import { graphql, useStaticQuery } from 'gatsby';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'aws',
    paragraphsCounters: {
        header: 1,
        mainGoals: 1,
        summary: 2,
    },
    subContentCounters: {
        about: {
            list: 3,
        },
    },
    contentGridPanel: {
        columnsCounter: 3,
        listItemsCounter: 2,
    },
    keyTakeawaysAndFutureDirections: {
        itemsCounter: 5,
    },
    caseStudies: [
        'churn',
        'salesPredictiveAlgorithm',
        'moneyTransferApplication',
    ],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

content.headerImageAlt = 'Diagram illustrating AWS cloud infrastructure';
content.about.alt =
    'AWS diagram with included regions, availability zones, and edge locations connected by a global network';
content.mainGoals.alt = 'AWS outlined colored logo in clouds';
content.keyTakeawaysAndFutureDirections.alt = 'AWS colored logo in cloud';
content.summary.alt =
    'AWS cloud infrastructure illustration diagram with elements representing different AWS services and features.';

export const useAwsCostOptimization = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: { eq: "single-case-study/aws/cloud.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: { eq: "single-case-study/aws/about.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: { eq: "single-case-study/aws/main-goals.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: { eq: "single-case-study/aws/summary.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                keyTakeawaysAndFutureDirectionsImage: file(
                    relativePath: {
                        eq: "single-case-study/aws/key-takeaways-and-future-directions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
